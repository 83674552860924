import React from "react";
import "./Workshop.css";
import workshop from "../../images/workshop.JPG";
import NewsLetter from "../Newsletter/NewsLetter";

function Workshop() {
  return (
    <seciton className="workshop__section">
      <div className="container">
        <div className="workshop__row">
          <div className="workshop__image">
            <img src={workshop} alt="" />
          </div>

          <div className="workshop__text">
            <h2>Workshop Title1</h2>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Blanditiis rerum necessitatibus quas eligendi beatae error iusto
              similique autem libero hic.
            </p>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi,
              repellendus sequi. Velit harum vero praesentium sit accusamus,
              iure odio illo at excepturi aut consequatur minus quos voluptatum
              quod et saepe sequi necessitatibus qui in facere.
            </p>
            <p>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit.
              Doloremque unde non facere quasi dolores excepturi aut commodi
              officiis aliquam tenetur ab reprehenderit nulla, assumenda velit!
            </p>
            <p>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Expedita
              inventore minus perferendis optio minima perspiciatis possimus
              delectus nam deleniti fugiat temporibus quam aspernatur
              dignissimos ea.
            </p>
            <div className="primary-btn">
              <a href="#!">Enquire</a>
            </div>
          </div>
        </div>

        <div className="workshop__row reverse">
          <div className="workshop__image">
            <img src={workshop} alt="" />
          </div>

          <div className="workshop__text">
            <h2>Workshop Title2</h2>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Blanditiis rerum necessitatibus quas eligendi beatae error iusto
              similique autem libero hic.
            </p>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi,
              repellendus sequi. Velit harum vero praesentium sit accusamus,
              iure odio illo at excepturi aut consequatur minus quos voluptatum
              quod et saepe sequi necessitatibus qui in facere.
            </p>
            <p>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit.
              Doloremque unde non facere quasi dolores excepturi aut commodi
              officiis aliquam tenetur ab reprehenderit nulla, assumenda velit!
            </p>
            <p>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Expedita
              inventore minus perferendis optio minima perspiciatis possimus
              delectus nam deleniti fugiat temporibus quam aspernatur
              dignissimos ea aperiam consectetur architecto cupiditate dicta in
              amet provident, nisi quibusdam. Perspiciatis, exercitationem! Vel,
              quaerat recusandae.
            </p>
            <div className="primary-btn">
              <a href="#!">Enquire</a>
            </div>
          </div>
        </div>
      </div>
      <NewsLetter />
    </seciton>
  );
}

export default Workshop;

import React from "react";
import "./Ngungwulah.css";
import BreadCrumb from "../components/BreadCrumb/BreadCrumb";
import ngungwulah from "../images/ngungwulah.JPG";
import TestimonialSldier from "../components/TestimonialSlider/TestimonialSldier";
import NewsLetter from "../components/Newsletter/NewsLetter";
import { AiOutlineCheck } from "react-icons/ai";
import Testimonial from "../components/HomeComponents/Testimonial";

function Ngungwulah() {
  return (
    <>
      <BreadCrumb title={"Ngungwulah"} />
      <section className="ngungwulah__section">
        <div className="container">
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit quia
            accusantium aliquid molestiae tempora repellat nisi soluta. Id eaque
            dolorum amet nisi quos recusandae, impedit dolore ipsa voluptatibus
            sit repellendus?
          </p>
          <div className="ngungwulah--row">
            <div className="ngungwulah--image">
              <img src={ngungwulah} alt="ngungwulah" />
            </div>
            <div className="ngungwulah--text">
              <h2>Ngungwulah Title</h2>
              <p>
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Esse
                temporibus officia deleniti, consectetur illum harum recusandae
                quos facere doloremque molestiae obcaecati ducimus quo animi
                saepe porro iusto quas libero sed commodi velit fugit assumenda
                dignissimos quisquam? Eveniet necessitatibus voluptatem, saepe
                quidem at quisquam, temporibus optio aspernatur asperiores
                laboriosam odit dolorem.
              </p>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugiat
                repellendus nobis beatae a doloremque nesciunt! Deleniti quasi
                aspernatur, esse eos dolore a ipsa excepturi consequuntur
                praesentium quod mollitia alias quisquam quaerat maiores
                ducimus? Doloremque voluptatibus expedita vel praesentium cumque
                ut!
              </p>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Laboriosam provident cupiditate quo ipsa nulla animi dolorem
                dicta doloremque incidunt rem, quam, facilis iste enim delectus
                sit consectetur commodi magni mollitia tempore deleniti fuga
                facere hic? Illum accusantium reiciendis dolorem ipsam.
              </p>
            </div>
          </div>
        </div>
        <NewsLetter />
        <div className="breathwork--banner">
          <h2>What are the benefits of Ngungwulah?</h2>
          <div className="breathwork--banner__grid">
            <div className="breathwork--text__col">
              <ul>
                <li>
                  <p>
                    <i>
                      <AiOutlineCheck />
                    </i>
                    Improves your core strength
                  </p>
                </li>
                <li>
                  <p>
                    <i>
                      <AiOutlineCheck />
                    </i>
                    Yoga can contribute to healthy bone density{" "}
                  </p>
                </li>
              </ul>
            </div>
            <div className="breathwork--text__col">
              <ul>
                <li>
                  <p>
                    <i>
                      <AiOutlineCheck />
                    </i>
                    Certain breathwork poses contribute to cardiovascular health
                  </p>
                </li>
                <li>
                  <p>
                    <i>
                      <AiOutlineCheck />
                    </i>
                    By supporting the lymphatic system, breathwork yoga
                    strengthens your immune system
                  </p>
                </li>
              </ul>
            </div>
            <div className="breathwork--text__col">
              <ul>
                <li>
                  <p>
                    <i>
                      <AiOutlineCheck />
                    </i>
                    You will be able to direct and master your own energy
                  </p>
                </li>
                <li>
                  <p>
                    <i>
                      <AiOutlineCheck />
                    </i>
                    You will be able to reach your highest potential
                  </p>
                </li>
              </ul>
            </div>
            <div className="breathwork--text__col">
              <ul>
                <li>
                  <p>
                    <i>
                      <AiOutlineCheck />
                    </i>
                    Improves your core strength
                  </p>
                </li>
                <li>
                  <p>
                    <i>
                      <AiOutlineCheck />
                    </i>
                    Yoga can contribute to healthy bone density{" "}
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <Testimonial />
    </>
  );
}

export default Ngungwulah;

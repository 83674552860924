import React from "react";
import "./ChildrenWork.css";
import children from "../images/children.jpg";
import BreadCrumb from "../components/BreadCrumb/BreadCrumb";
import TestimonialSldier from "../components/TestimonialSlider/TestimonialSldier";
import Testimonial from "../components/HomeComponents/Testimonial";
import Enquireform from "../components/EnquireForm/Enquireform";

function ChildrenWork() {
  return (
    <>
      <section className="childrenwork__section">
        <BreadCrumb title={"Working with children"} />
        <div className="container" style={{ paddingTop: "8em" }}>
          <div className="childrenwork__text">
            <h2>Working With Children</h2>

            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Expedita
              iusto molestiae necessitatibus eos aut eveniet placeat pariatur
              nihil voluptatibus minus eligendi hic, rerum accusamus quibusdam
              sint blanditiis itaque, vel nesciunt.
            </p>

            <p>
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ipsa
              assumenda repellendus sit neque sed, obcaecati labore architecto
              vel, ab repellat necessitatibus! Excepturi optio rem nostrum eum
              alias nihil quibusdam suscipit esse, at dolorem quae voluptas
              itaque nesciunt doloremque accusamus architecto repellendus! Animi
              esse sint facilis ab omnis illum expedita sunt.
            </p>
            <div className="primary-btn">
              <a href="#!">Enquire</a>
            </div>
            <div className="childrenwork__text--grid">
              <div className="text-col">
                <h3>Nurturing Connections</h3>
                <p>
                  The program guides young people to Indigenous ways of knowing
                  and being as a pathway to a greater understanding of the land,
                  water, and Identity to increase the quality of their
                  relationship and connectedness to Culture. It is recognized as
                  a wellbeing program featuring Aboriginal language, movement
                  (the rhythm of Country), meditation, dadirri, yarning, songs,
                  and art. The content is delivered in a contemporary style that
                  allows Culture to be visible again.
                </p>
                <p>
                  It encourages young people to develop a deep appreciation for
                  Country and heal.{" "}
                </p>
              </div>
              <div className="text-col">
                <h3>Traditional Knowledge</h3>
                <p>
                  The program recognises the importance of Indigenous knowledge
                  and concepts, and works to help participants to connect to
                  Country, exploring Indigenous ways of knowing, being, and
                  thinking as a pathway to a greater understanding of the land,
                  water, and our peoples. The program encourages Koorie
                  expression through wellbeing, stories, art and music to accept
                  the many benefits to be gained from meditation, physical
                  movement, mindfulness, and gaining a sense of connection to
                  place.
                </p>
                <p>
                  The program also features Aboriginal language, shares
                  Aboriginal Culture and supports sustainability learning.
                </p>
              </div>
              <div className="text-col">
                <h3>Proven Pedagogy</h3>
                <p>
                  The program weaves through the “8 Ways of Learning” pedagogy
                  that is based on research conducted by Aboriginal education
                  scholars in NSW. We combine Dadirri, inner deep listening and
                  Wayapa Wuurk, a unique mind-body practice that combines
                  multi-sensory meditation with traditional movement. These
                  mind-body-earth practices link personal wellbeing with
                  community/environmental connection, building a sustainable
                  society together and moving towards a healed generation.
                </p>
                <p>
                  The program can be delivered remotely or face-to face, and is
                  a combination of outdoor education and classroom inclusion.
                </p>
              </div>
            </div>
          </div>
          <div className="childrenwork__row">
            <div className="col">
              <h3>Mentoring Indigenous Youth</h3>
              <p>
                Lee has developed a pathway in the education space that provides
                Indigenous youth with an opportunity to a 12-month scholarship
                at Thornbury Primary School in Melbourne. Thornbury Primary
                School, Winner of Koorie Excellence in Education will provide
                practical work experience and relevant educational support and
                mentoring. The trainee will also attain a Certificate III in
                Education Support with further qualification, scholarships, and
                employment pathways. The Scholarship is also available in other
                metro and regional areas.{" "}
              </p>
            </div>
            <div className="col">
              <img src={children} alt="working with children" />
            </div>
          </div>
        </div>
      </section>
      <Enquireform />
      <Testimonial />
    </>
  );
}

export default ChildrenWork;

import React, { useEffect, useState } from "react";
import axios from "axios";
import BreadCrumb from "../components/BreadCrumb/BreadCrumb";
import "./Wayapa.css";
import banner1 from "../images/home-banner1.jpg";
import Testimonial from "../components/HomeComponents/Testimonial";
import TestimonialSldier from "../components/TestimonialSlider/TestimonialSldier";
import Enquireform from "../components/EnquireForm/Enquireform";

function WayapaScreen() {
  const [imageURLs, setImageURLs] = useState([]);

  useEffect(() => {
    fetchImages();
  }, []);

  const fetchImages = async () => {
    try {
      const response = await axios.get(
        "https://admin.oncountryconnections.com.au/api/wayapaimage"
      );
      console.log("API response:", response.data); // Log the API response
      if (Array.isArray(response.data.urls)) {
        setImageURLs(response.data.urls);
      } else {
        console.error(
          "Image URLs are not in the expected format:",
          response.data.urls
        );
      }
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };
  return (
    <>
      <section className="wayapa__section">
        <BreadCrumb title={"Wayapa Wuurrk"} />
        <div className="container" style={{ paddingTop: "2em" }}>
          <h3>
            <strong>Wayapa® </strong>
            is an earth connection practice that is based on ancient Indigenous
            wisdom that focuses on taking care of the Earth as the starting
            point for creating Earth Mind Body Spirit well-being.
          </h3>
          <div className="wayapaimage">
            <img src={banner1} alt="" />
          </div>
          <div className="wayapa-row">
            <div className="wayapa__text">
              <p>
                Wayapa Wuurrk is based on the Indigenous concept of reciprocity,
                that we must give to receive from Mother Earth.
              </p>

              <p>
                Today, for most of us, we have become disconnected from our
                environment, from our tribe, from our food and from our Spirit.
                Through a combination of Earth mindfulness, narrative
                meditation, a series of physical movements and taking action to
                look after the planet, Wayapa® provides a sense of belonging to
                the Earth while creating holistic well-being.
              </p>

              <p>
                Jem Stone, founder of On Country Connections is the Wayapa
                Wuurrk Cultural lead, Training manager and a licensed Wayapa
                Wuurrk practitioner, trainer, and early years educator. Jem has
                been involved with research with Wayapa Wuurrk and psychology
                alongside Deakin University, and in the development stage of a
                Wayapa and CBT model, weaving Indigenous and western models for
                mental health care. Please read the peer reviewed article in
                Australian Psychologist Journal here{" "}
                <a
                  href="https://www.tandfonline.com/doi/full/10.1080/00050067.2024.2322710"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Australian Psychologist Journal
                </a>
              </p>
              <p>
                Contact us below for Wayapa Wuurrk to bring earth connection
                into your next event. Individuals, schools, communities and
                businesses all can enjoy this connection.
              </p>
            </div>
            {/* <div className="wayapa__image">
              <img src={wayapa} alt="wayapa wuurrk" />
            </div> */}
          </div>
          <div className="wayapa__grid">
            {imageURLs.map((url, index) => (
              <div className="wayapa__image" key={index}>
                <img src={url} alt={`Consultant ${index + 1}`} />
              </div>
            ))}
          </div>
        </div>
      </section>
      <Enquireform />
      <Testimonial />
    </>
  );
}

export default WayapaScreen;

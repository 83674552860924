import React from "react";
import corporate from "../images/workshop.JPG";
import "./CorporateEvents.css";
import BreadCrumb from "../components/BreadCrumb/BreadCrumb";
import TestimonialSldier from "../components/TestimonialSlider/TestimonialSldier";
import Testimonial from "../components/HomeComponents/Testimonial";
import Enquireform from "../components/EnquireForm/Enquireform";

function CorporateEvents() {
  return (
    <>
      <BreadCrumb title={"Upcoming Events and Training"} />
      <section className="corporate__section">
        <div className="container">
          <h2>Upcoming Events and Training</h2>
          <p>
            My workshops are the result of my genuine desire to inspire change.
            I can deliver a special experience that is tailored to your needs
            either in person or online.
          </p>

          {/* <div className="primary-btn">
            <a href="#!">Enquire</a>
          </div> */}
          {/* <div className="childrenwork__text--grid">
            <div className="text-col">
              <h3>Young People </h3>
              <p>
                Jem has created a pathway in the field of education that offers
                Indigenous students relevant educational support and mentoring
                as well as real-world employment experience. Additionally, the
                trainee will earn a Certificate in Education Support while
                pursuing other credentials, scholarships, and career
                opportunities.
              </p>
              <p>
                It encourages young people to develop a deep appreciation for
                Country and heal.
              </p>
            </div>
            <div className="text-col">
              <h3>Traditional Knowledge</h3>
              <p>
                The program recognises the importance of Indigenous knowledge
                and concepts, and works to help participants to connect to
                Country, exploring Indigenous ways of knowing, being, and
                thinking as a pathway to a greater understanding of the land,
                water, and our peoples. The program encourages Koorie expression
                through wellbeing, stories, art and music to accept the many
                benefits to be gained from meditation, physical movement,
                mindfulness, and gaining a sense of connection to place.
              </p>
              <p>
                The program also features Aboriginal language, shares Aboriginal
                Culture and supports sustainability learning.
              </p>
            </div>
            <div className="text-col">
              <h3>Proven Pedagogy</h3>
              <p>
                The program weaves through the “8 Ways of Learning” pedagogy
                that is based on research conducted by Aboriginal education
                scholars in NSW. We combine Dadirri, inner deep listening and
                Wayapa Wuurk, a unique mind-body practice that combines
                multi-sensory meditation with traditional movement. These
                mind-body-earth practices link personal wellbeing with
                community/environmental connection, building a sustainable
                society together and moving towards a healed generation.
              </p>
              <p>
                The program can be delivered remotely or face-to face, and is a
                combination of outdoor education and classroom inclusion.
              </p>
            </div>
          </div> */}
          <div className="corporate__upcoming-events">
            <div className="corporate__event--image">
              <img src={corporate} alt="" />
            </div>
            <div className="corporate__event--text">
              <h3>Honouring the Spring Equinox</h3>
              <p>Date: Saturday 23 September 2023 2:30 PM - 6:30 PM (UTC+10)</p>
              <p>
                Location: Elwood Sailing Club 5 Elwood Foreshore, , Boon Wurrung
                Country, Elwood VIC 3184
              </p>
              <p>
                Guided by: Elizabeth-Proud Mura woman from the Amazon, Jem-
                Proud Bundjalung woman with mixed heritage, Damian- Proud Nde
                (Apache Jicarilla) and Dineh (Navajo) Man, Kirt- Proud Torres
                Strait Islands Man.
              </p>
              <p>
                Details: The equinox is a time to reflect on balance as the sun
                gives us equal day and night . Join us for this rare 4 hour
                Indigenous collaboration of original ways of knowing, being and
                doing, in right relationship with original knowledge holders as
                we celebrate the new cycle of the sun.
              </p>
              <p>
                Sit in circle with us for knowledge sharing, ritual, practices
                of connection, wellbeing, movement, sound and more.
              </p>
              <p>
                Wear comfortable clothing and bring something comfortable to sit
                on eg a yoga mat and cushion. We look forward to seeing you
                there!
              </p>
              {/* <ul>
                <li>
                  {" "}
                  <p> Welcome Ceremony</p>
                </li>
                <li>
                  <p>Accomodation and Catering</p>
                </li>
                <li>
                  {" "}
                  <p> All required resources</p>
                </li>
                <li>
                  {" "}
                  <p> Bus Transfers</p>
                </li>
              </ul> */}
            </div>
          </div>
          <div className="corporate__upcoming-events">
            <div className="corporate__event--image">
              <img src={corporate} alt="" />
            </div>
            <div className="corporate__event--text">
              <h3>AMAPP conference and fundraiser</h3>
              <p>Date: Sat 23rd Sep 2023, 9:00 am - 5:30 pm AEST</p>
              <p>
                Location: Harm Reduction Victoria Inc. 299-305 Victoria St,
                Brunswick VIC 3056, Australia
              </p>
              <p>
                Guided by: Professor David Castle (Psychiatrist) Dr Lani Roy
                (Psychologist/Social Worker), Dr Bianca Sebben (Clinical
                Psychologist), Indigenous Psychedelic Assisted Therapies (IPAT),
                Jem Stone, Scarlet Barnett, Kirk Mallie,
              </p>
              <p>
                Details: This conference serves as a platform for knowledge
                exchange and as a fundraising event to support the ongoing work
                of AMAPP in promoting responsible and safe psychedelic-assisted
                therapies. By attending and participating in this conference,
                you will be making a significant contribution to the advancement
                of this field and helping to shape the future of
                psychedelic-assisted psychotherapy in Australia.
              </p>
              <p>
                Join us on September 23rd as we come together to co-create an
                ethical and accountable psychedelic-assisted psychotherapy
                ecosystem, and together, unlock the transformative potential of
                these powerful therapeutic tools.
              </p>
              <p>
                For more info on AMAPP please visit the Australian
                Multidisciplinary Association for Psychedelic Practitioners at
                www.amapp.au
              </p>
            </div>
          </div>
          <div className="primary-btn">
            <a
              href="https://linktr.ee/JemStone"
              target="_blank"
              rel="noreferrer"
            >
              View All Events
            </a>
          </div>
        </div>
      </section>
      <Enquireform />
      <Testimonial />
    </>
  );
}

export default CorporateEvents;

import "./App.css";
import React from "react";
import Header from "./components/Header/Header";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Footer from "./components/Footer/Footer";
import HomeScreen from "./screens/HomeScreen";
import WorkShopScreen from "./screens/WorkShopScreen";
import ConsultancyScreen from "./screens/ConsultancyScreen";
import Practitioner from "./components/Training/Practitioner";
import Cultural from "./components/Training/Cultural";
import WayapaScreen from "./screens/WayapaScreen";
import WealiScreen from "./screens/WealiScreen";
import ChildrenWork from "./screens/ChildrenWork";
import IpatScreen from "./screens/IpatScreen";
import Breathwork from "./screens/Breathwork";
import Ngungwulah from "./screens/Ngungwulah";
import CorporateEvents from "./screens/CorporateEvents";
import PublicEvents from "./screens/PublicEvents";
import Contact from "./screens/Contact";
import About from "./screens/About";
import ScrollToTop from "./components/ScrollToTop";

const App = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Header />
      <Routes>
        <Route path="/" element={<HomeScreen />} />
        <Route path="/workshops" element={<WorkShopScreen />} />
        <Route path="/consultancy" element={<ConsultancyScreen />} />
        {/* <Route path="/practitioner-training" element={<Practitioner />} /> */}
        {/* <Route path="/cultural-awareness-training" element={<Cultural />} /> */}
        <Route path="/wayapa-wuurrk" element={<WayapaScreen />} />
        <Route path="/we-al-li" element={<WealiScreen />} />
        {/* <Route path="/working-with-children" element={<ChildrenWork />} /> */}
        <Route path="/ipat" element={<IpatScreen />} />
        <Route path="/rebirthing-breathwork" element={<Breathwork />} />
        <Route path="/ngungwulah" element={<Ngungwulah />} />
        <Route path="/training-events" element={<CorporateEvents />} />
        {/* <Route path="/public-events" element={<PublicEvents />} /> */}
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/about-us" element={<About />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default App;

import React from "react";
import "./Videos.css";
import image1 from "../../images/podcast.d0b73f5796c0433ff581.png";
import image2 from "../../images/spotify.839f56be63fdee1c25a3.jpeg";

const PodcastCard = ({ src, title, link, image }) => {
  return (
    <div className="video-container">
      <a className="podcast-card" href={link}>
        {image ? (
          <div className="podcast-image">
            <img src={image} alt="" />
          </div>
        ) : (
          <>
            <div className="podcast-image">
              <div
                style={{
                  width: "100%",
                  height: 0,
                  position: "relative",
                  paddingBottom: "56.25%",
                }}
              >
                <iframe
                  src={src}
                  frameBorder="0"
                  width="100%"
                  height="100%"
                  style={{
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    left: 0,
                    top: 0,
                    overflow: "hidden",
                  }}
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </>
        )}
        <div className="podcast-details">
          <p>Video</p>
          <h3>{title}</h3>
          <h3 id="link-to-podcast">
            <a
              href="https://www.sbs.com.au/language/nitv-radio/en/podcast-episode/a-wayapa-wuurrk-practitioner-shares-the-wisdom-of-this-aboriginal-wellness-practice/xiqao0wut"
              rel="noreferrer"
              target="_blank"
            >
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 16 16"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"
                ></path>
              </svg>
            </a>
            Start Playing
          </h3>
        </div>
      </a>
    </div>
  );
};
const VideosSection = () => {
  return (
    <div className="videos-section">
      <h2>Videos and Podcasts</h2>
      <div className="container">
        <PodcastCard
          src="https://www.youtube.com/embed/UPemv2mXmQ0?si=j4v-ni0CkFgn-Qwo"
          title="Conscious Breath Awareness"
          link="https://www.youtube.com/watch?v=UPemv2mXmQ0"
        />
        <PodcastCard
          src="https://www.youtube.com/embed/tZkZ0_-yYXM?si=KW7lOYcHC6rtPqQi"
          title="Rain Meditation by Jem Stone"
          link="https://www.youtube.com/watch?v=tZkZ0_-yYXM"
        />
        <PodcastCard
          src="https://www.youtube.com/embed/LdNkcL9aFhU?si=doHfaPSqp7snKfSK"
          title="The Old New: contemplating Country today with Jem Stone"
          link="https://www.youtube.com/watch?v=LdNkcL9aFhU"
        />
        <PodcastCard
          src="https://streamable.com/e/tuxbxw?loop=0"
          title="Conscious Breath Awareness"
          link="https://www.youtube.com/watch?v=UPemv2mXmQ0"
          image={image1}
        />
        <PodcastCard
          src="https://streamable.com/e/9h01iq"
          title="Rain Meditation by Jem Stone"
          link="https://www.youtube.com/watch?v=tZkZ0_-yYXM"
          image={image1}
        />
        <PodcastCard
          src="https://streamable.com/e/5ojb3p"
          title="The Old New: contemplating Country today with Jem Stone"
          link="https://www.youtube.com/watch?v=LdNkcL9aFhU"
          image={image2}
        />
        {/* <a
          className="podcast-card"
          href="https://www.sbs.com.au/language/nitv-radio/en/podcast-episode/a-wayapa-wuurrk-practitioner-shares-the-wisdom-of-this-aboriginal-wellness-practice/xiqao0wut"
          rel="noreferrer"
          target="_blank"
        >
          <div className="podcast-image">
            <img src={image1} alt="" />
          </div>
          <div className="podcast-details">
            <p>Podcast</p>
            <h3>
              A Wayapa Wuurrk practitioner shares the wisdom of this Aboriginal
              wellness practice
            </h3>
            <h3 id="link-to-podcast">
              <a
                href="https://www.sbs.com.au/language/nitv-radio/en/podcast-episode/a-wayapa-wuurrk-practitioner-shares-the-wisdom-of-this-aboriginal-wellness-practice/xiqao0wut"
                rel="noreferrer"
                target="_blank"
              >
                Start Playing
              </a>
            </h3>
          </div>
        </a>
        <a
          className="podcast-card"
          href="https://www.sbs.com.au/language/nitv-radio/en/podcast-episode/new-courses-to-offer-a-safe-space-for-first-nations-australians-in-the-wellness-industry/zd9mth4o9"
          rel="noreferrer"
          target="_blank"
        >
          <div className="podcast-image">
            <img src={image1} alt="" />
          </div>
          <div className="podcast-details">
            <p>Podcast</p>
            <h3>
              New courses to offer a safe space for First Nations Australians in
              the wellness industry
            </h3>
            <h3 id="link-to-podcast">
              <a
                href="https://www.sbs.com.au/language/nitv-radio/en/podcast-episode/new-courses-to-offer-a-safe-space-for-first-nations-australians-in-the-wellness-industry/zd9mth4o9"
                rel="noreferrer"
                target="_blank"
              >
                Start Playing
              </a>
            </h3>
          </div>
        </a>
        <a
          className="podcast-card"
          href="https://open.spotify.com/episode/6zKmjtjIb3UHlQ5dU9gDnG"
          rel="noreferrer"
          target="_blank"
        >
          <div className="podcast-image">
            <img src={image2} alt="" />
          </div>
          <div className="podcast-details">
            <p>Podcast</p>
            <h3>
              Creating Safer Spaces In The Wellbeing Landscape And Beyond With
              Jem Stone
            </h3>
            <h3 id="link-to-podcast">
              <a
                href="https://open.spotify.com/episode/6zKmjtjIb3UHlQ5dU9gDnG"
                rel="noreferrer"
                target="_blank"
              >
                Start Playing
              </a>
            </h3>
          </div>
        </a> */}
      </div>
    </div>
  );
};

export default VideosSection;

import React from "react";
import "./LandingSection.css";
import banner from "../../images/home-banner.jpg";

const LandingSection = () => {
  return (
    <div className="landing-section">
      <div className="home-banner">
        <img src={banner} alt="" />
        <h2 className="credit-text">
          On Country Connections by Christopher Edwards-Haines
        </h2>
      </div>
    </div>
  );
};

export default LandingSection;

const testimonials = [
  {
    id: "1",
    name: "Melissa L",
    message:
      "I had the opportunity to engage in a re-birthing breathwork session with Jem one-on-one. I was absolutely blown away by her ability to support, hold space, regulate and open my heart to new ways of being. Her intuitive connection to the universe mixed with her powerful breathwork knowledge allowed me to release old trauma and see the world from a new perspective. The journey ahead looks clear and exceptional bright and I look forward to connecting more with this women and her true wisdom.I highly recommend you take the step to be able to engage with your parasympathetic nervous system as old conditioning and processes are fragmented and released. Thank you",
  },
  {
    id: "2",
    name: "John B",
    message:
      "Jem is the most intuitive wellness practitioner I have ever worked with. Her 1-1 breathwork sessions both wow and heal me every visit.",
  },
  {
    id: "3",
    name: "Natacha N",
    message:
      "Jem guides very well in the process of the breathwork and offers a very gentle and beautiful presence. It is a transformational work very powerful!!",
  },
  {
    id: "4",
    name: "Cultural Training for wellness practitioners Participant 2022",
    message:
      "Before this eye-opening training I felt like a disconnected outsider from Aboriginal culture, but after I felt I now had a connection point and forum to be learning and yarning which makes it possible for me to be a part of the Reconciliation process. I felt inspired to learn more and Jem and Eve gave us so many recommended books, videos and courses to continue learning after the training that have deepened my knowledge, connection and motivation to be a good ally.",
  },
  {
    id: "5",
    name: "Cultural Training for Yoga Teachers participant 2023",
    message:
      "Thank you Eve and Jem for this very informative and pioneering workshop for Yogis. It helped me to know more about our own beautiful ancient culture in Australia and it is a great step towards reconciliation, raising awareness and empowering us to be better citizens and Yoga teachers. It has encouraged me to connect further to this sacred land and bring that into my practice, and to connect further to my local Aboriginal and Torres Strait Islander communities.",
  },
  {
    id: "6",
    name: "Cultural Training for Yoga Teachers participant 2023",
    message:
      "Practical, inspirational and informative workshop which should be made compulsory for all yoga teachers.",
  },
  {
    id: "7",
    name: "Rachael B",
    message:
      "Jem is a rare soul and beautiful spirit who guides her class through Wayapa with warmth, humility and compassion. She’s generous with her knowledge, wisdom and life experience. Jem creates a safe space for the group where sharing vulnerably is easy and as a result lasting, deep friendships are quickly formed. I can’t recommend Jem highly enough as a trainer and guide and can say with all sincerity she’s changed my life in 7 days, reacquainting me with everything I’d been searching for without realising it already exists within.",
  },
  {
    id: "8",
    name: "Ziggy R",
    message:
      "The Wayapa Wuurrk training is not only a beautiful opening into ancient knowledge, but a true opportunity to deepen your relationship with the earth, yourself and your spirit. Sara and Jem are experts in their field and embody the teachings of Wayapa so effortlessly that you learn simply by speaking with them, let alone the carefully constructed curriculum they have created. Whether you are already a wellness practitioner looking to expand your understanding of the world and your offerings, or this will be your first venture into teaching, the ways and wisdom of Wayapa is desperately needed in our modern world and couldn’t be shared by more real, warm and wonderful leaders.",
  },
  {
    id: "9",
    name: "Cultural Training Deakin University participant",
    message:
      "The Wayapa Wuurrk team, Jamie, Sara, and Jem, are gentle, respectful, and charismatic in sharing their wisdom. They tell stories and connect us back to our real selves, and to our own ancestry, and show us that we’re all connected, to each other, and to the earth. Their teachings make it seem possible to heal the planet, and live a healthier and more connected life.",
  },
  {
    id: "10",
    name: "Cultural Training Participant Deakin University",
    message:
      "Deep respect for the connection and commitment to Mother Nature. We need to remember their ways/wisdom.",
  },
  {
    id: "11",
    name: "Elizabeth W",
    message:
      "This session achieved more in a few hours, than some interventions achieve in weeks or months. A complete restoring of the body, mind and spirit. Thank you for facilitating this understanding of our deep and nourishing connection to the planet and to each other.",
  },
  {
    id: "12",
    name: "Liz",
    message:
      "A deep appreciation for the original and present custodians of this land. So much wisdom, culture and insight. Thank you.",
  },
  {
    id: "13",
    name: "Ainsley S",
    message:
      "The session definitely provided opportunities for us to learn and reflect on how we, individually, and collectively, can be more aware of the land and our impacts on it. Being led through a Wayapa practice also helped me to feel more grounded and apply all of the elements.",
  },
  {
    id: "14",
    name: "Kaz Brodie",
    message:
      "I have never experienced anything like it! It was honestly life changing for me. Unbelievably special and completely magical. My connection with Mother Earth has always been deep but after that, it is a whole other beautiful level and my love, admiration and respect - and heartache for indigenous people and culture also runs even deeper now I will forever be so grateful for what you guys so kindly shared. Thank you, with all my heart xo",
  },
  {
    id: "15",
    name: "Katherine Johnson | Professor (Melbourne University School of Psychological Sciences | MDHS)",
    message:
      "Jem Stone conducted a three hour Wayapa Wuurk workshop with a group of my ADHD students in July 2024. It was a deeply moving experience. Jem held everyone's attention for the entire three hours. Through her storytelling and her evocations of nature, she highlighted the importance of feeling connected with nature and of taking care of everyone and everything in the natural world. She led us through several mindful meditations that allowed us to break away from our everyday worries and to reconnect with ourselves. She provided a safe place for all of us to feel connected with our ancient land. It was a wonderful workshop.",
  },
];
export default testimonials;

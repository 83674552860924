import React, { useRef, useState } from "react";
import emailjs from "emailjs-com";
import "./Contact.css";
import BreadCrumb from "../components/BreadCrumb/BreadCrumb";

function Contact() {
  const form = useRef();
  const [loading, setLoading] = useState(false); // Loader state

  const sendEmail = (e) => {
    e.preventDefault();

    setLoading(true); // Show loader when sending starts

    emailjs
      .sendForm(
        "service_ipxbjba", // Your service ID
        "template_i6y5wp5", // Your template ID
        form.current, // The form reference
        "m6WiG50t9Q4tgBgY_" // Your public key
      )
      .then(
        (result) => {
          alert(
            "Your message has been sent successfully. We will get back to you soon."
          );
          form.current.reset(); // Clear form after successful submission
          setLoading(false); // Hide loader after success
        },
        (error) => {
          alert("Something went wrong. Please try again later");
          setLoading(false); // Hide loader after error
        }
      );
  };
  const handleAnchorClick = (e) => {
    e.preventDefault();
    form.current.dispatchEvent(
      new Event("submit", { cancelable: true, bubbles: true })
    );
  };
  return (
    <>
      <BreadCrumb title={"Contact Us"} />
      <section className="contact__section">
        <div className="container">
          <h3>Register your interest</h3>
          <span>
            <p>Retreats</p>
            <p>|</p>
            <p>Wellness</p>
            <p>|</p>
            <p>Training</p>
            <p>|</p>
            <p>Events</p>
          </span>
          <p>
            Please fill in this form to register your interest and I'll be in
            touch soon!
          </p>
          <div className="form-section">
            <form ref={form} onSubmit={sendEmail}>
              <div className="form--grid">
                <div className="input-group">
                  <input type="text" placeholder="First Name *" name="fname" />
                </div>
                <div className="input-group">
                  <input type="text" placeholder="Last Name *" name="lname" />
                </div>
                <div className="input-group">
                  <input
                    type="email"
                    placeholder="Email Address *"
                    name="email"
                  />
                </div>
                <div className="input-group">
                  <input
                    type="text"
                    placeholder="Phone Number *"
                    name="phone"
                  />
                </div>
                <div className="input-group">
                  <input
                    type="text"
                    placeholder="Organization Name"
                    name="organization"
                  />
                </div>
                <div className="input-group">
                  <span className="hidden-placeholder">Select Event Date</span>
                  <input
                    type="date"
                    placeholder="Select Event Date"
                    name="date"
                  />
                </div>
                <div className="input-group">
                  <input
                    type="text"
                    placeholder="Number of Participants"
                    name="numberOfParticipants"
                  />
                </div>
                <div className="input-group">
                  <input
                    type="text"
                    placeholder="Event Name"
                    name="event_name"
                  />
                </div>
              </div>

              <div className="input-group">
                <textarea
                  name="message"
                  cols="30"
                  rows="5"
                  placeholder="Send a message"
                ></textarea>
              </div>

              <div className="primary-btn" style={{ marginTop: "1em" }}>
                {/* Trigger form submission programmatically on anchor click */}
                <a href="submit" onClick={handleAnchorClick}>
                  {loading ? "Processing..." : "Submit"} {/* Loader message */}
                </a>
              </div>
            </form>
            {loading && (
              <div className="loader">Processing your request...</div>
            )}{" "}
            {/* Loader */}
          </div>
        </div>
      </section>
    </>
  );
}

export default Contact;

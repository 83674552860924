import BreadCrumb from "../BreadCrumb/BreadCrumb";
import "./Training.css";
import React from "react";
import cultural from "../../images/cultural.JPG";

function Cultural() {
  return (
    <section className="practitioner__section">
      <BreadCrumb title={"Cultural Awareness Training"} />
      <div className="container" style={{ padding: "8em 0" }}>
        <h3>Cultural Awareness Training</h3>
        <p>
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quas
          molestias at, error consequuntur dolor voluptatum. Alias ipsam nobis
          explicabo laudantium quibusdam voluptas ducimus non nihil. At ex fuga
          repellendus non!
        </p>
        <div className="practitioner--row">
          <div className="practitioner__text">
            <h2>Cultural Awareness Training Title</h2>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum,
              inventore! Hic similique dolorem sint odio nesciunt nostrum quos
              totam quia! At excepturi sint asperiores consequuntur ratione
              aspernatur beatae libero corrupti natus rerum! Sint labore fugiat
              earum id? Itaque placeat nihil voluptas optio ipsam animi laborum
              minima, id, mollitia pariatur eum.
            </p>

            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Possimus
              dolore, dolor assumenda rerum impedit nulla quibusdam, ea
              exercitationem molestiae iure totam dicta eos illo doloremque
              deserunt libero vero veritatis! Veniam delectus illum, reiciendis
              obcaecati neque impedit! Quo ducimus aut animi?
            </p>

            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Ut
              dignissimos voluptate temporibus architecto iusto quas molestias,
              amet quam! Earum, labore. Nobis, veritatis unde! Totam, eligendi.
            </p>
          </div>

          <div className="practitioner__image">
            <img src={cultural} alt="" />
            <img src={cultural} alt="" />
          </div>
        </div>
      </div>

      {/* <TestimonialSldier /> */}
    </section>
  );
}

export default Cultural;

import React, { useEffect, useState } from "react";
import axios from "axios";
import BreadCrumb from "../components/BreadCrumb/BreadCrumb";
import consultancy from "../images/culturalconsultancy-1.jpg";
import "./Consultancy.css";
import Testimonial from "../components/HomeComponents/Testimonial";
import TestimonialSldier from "../components/TestimonialSlider/TestimonialSldier";
import Enquireform from "../components/EnquireForm/Enquireform";
import { Link } from "react-router-dom";

function ConsultancyScreen() {
  const [imageURLs, setImageURLs] = useState([]);

  useEffect(() => {
    fetchImages();
  }, []);

  const fetchImages = async () => {
    try {
      const response = await axios.get(
        "https://admin.oncountryconnections.com.au/api/consultancyimage"
      );
      console.log("API response:", response.data); // Log the API response
      if (Array.isArray(response.data.urls)) {
        setImageURLs(response.data.urls);
      } else {
        console.error(
          "Image URLs are not in the expected format:",
          response.data.urls
        );
      }
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };
  return (
    <>
      <section className="consultant__section">
        <BreadCrumb title={"Cultural consultancy & Training"} />
        <div className="consultancy__screen">
          <div className="container">
            <div className="consultancy-row ">
              <div className="consultancy--text">
                <h2>
                  Cultural Consultancy, Competency Training and Staff Enrichment
                </h2>
                <p>
                  On Country Connections compassionately creates a safe space
                  for all to reflect on their current practices through the lens
                  of cultural safety. Our cultural consultancy includes
                  one-on-one or group sessions.
                </p>

                <p>
                  We open in-depth conversations about belonging, truth-telling
                  and healing. Original knowledge is shared alongside respectful
                  discussion about the line between cultural appreciation and
                  appropriation.
                </p>

                <p>
                  Cultural consultancy and anti-racism training is available for
                  individuals and organisations. We can facilitate the creation
                  and implementation of programs to suit specific needs, one on
                  one, small intimate groups or at an organisational level.We
                  specialise in staff enrichment and wellness days, tailored to
                  your needs.
                </p>
                <p className="new-para-text">
                  <span>
                    AHPRA has announced the new guidelines and competencies for
                    for registered members working with Aboriginal and Torres
                    Straits Island peoples.
                  </span>
                  <a
                    href=" https://www.ahpra.gov.au/documents/default.aspx?record=WD24%2F33827&dbid=AP&chksum=fuZr8llQqn6qCLZBFoa4gA%3D%3D"
                    target="_blank"
                  >
                    (read here)
                  </a>
                </p>
                <p className="new-para-text">
                  <em>
                    The Professional competencies for psychologists are in
                    effect from 1 December 2025.
                  </em>
                </p>
                <p className="new-para-text">
                  {" "}
                  <span>
                    We offer an endorsed Culturally Informed Trauma Integrated
                    Healing Approach to Care and Practice for Working with
                    Aboriginal and Torres Strait Islands Peoples which provides
                    training in these competencies.
                  </span>
                </p>
              </div>
              <div className="consultancy--image">
                <img src={consultancy} alt="consultancy" />
              </div>
            </div>
            <div className="consultant__grid">
              {imageURLs.map((url, index) => (
                <div className="consultant__image" key={index}>
                  <img src={url} alt={`Consultant ${index + 1}`} />
                </div>
              ))}
            </div>
          </div>

          {/* <div className="consultancy-row reverse">
            <div className="consultancy--text">
              <h2>Consultancy Title</h2>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Doloremque ipsam quasi natus sunt fuga recusandae consequatur
                earum harum qui officiis!
              </p>

              <p>
                Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                Repellendus ipsam quae voluptatum sunt adipisci placeat quas
                voluptate, tempora officiis, iure modi? Ducimus in doloremque
                pariatur nostrum consectetur. Soluta neque nisi vitae corrupti
                ut labore perspiciatis consequatur, incidunt tenetur, laborum
                quae?
              </p>

              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Quibusdam, officia nihil neque incidunt nobis laboriosam
                suscipit est id maxime et, voluptatum possimus vitae asperiores
                esse.
              </p>

              <p>
                Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                Pariatur voluptatibus doloribus, architecto numquam iusto
                maiores ad quod, dolores reprehenderit consectetur incidunt
                error voluptatum iste rem perferendis quia recusandae, eaque
                aspernatur.
              </p>
            </div>
            <div className="consultancy--image">
              <img src={consultancy} alt="consultancy" />
            </div>
          </div> */}
        </div>

        <div className="consultancy-banner">
          <div className="consultancy-banner__points">
            <h3>Training & Workshops can include:</h3>

            <ul>
              <li>
                - APHRA Cultural Competency Guidelines & Requirements for
                Working with Aboriginal and Torres Strait Islands Peoples
              </li>
              <li>
                - Culturally Informed Trauma Integrated Healing Approach to Care
                and Practice
              </li>
              <li>- How to make a heartfelt Acknowledgement of Country</li>
              <li>
                - Cultural Training for Primary Health and Wellness
                practitioners{" "}
              </li>
              <li>- Developing worker skills & Staff Enrichment</li>
            </ul>
            <Link to="/ipat" className="ipat-link">
              Please also see Consultancy for Psychedelic Assisted Therapy
            </Link>
            <p>
              Contact us below to begin training and consultancy tailored to
              your needs.
            </p>
          </div>
        </div>
      </section>
      <Enquireform />
      <Testimonial />
    </>
  );
}

export default ConsultancyScreen;

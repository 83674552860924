import React, { useState, useEffect } from "react";
import "./Header.css";
import logo from "../../images/Logo1.png";
import logo1 from "../../images/Logo-2.png";
import { Link, NavLink } from "react-router-dom";
import { IconContext } from "react-icons";
import * as faIcons from "react-icons/fa";
import * as aiIcons from "react-icons/ai";

const Header = () => {
  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    // Add a scroll event listener when the component mounts
    window.addEventListener("scroll", handleScroll);

    // Remove the scroll event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function handleScroll() {
    const scrollY = window.scrollY;
    if (scrollY > 180) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  }

  const headerClassName = `main-header sticky-header ${
    scrolling ? "active" : ""
  }`;

  // sidebar menu
  const [mobileSidebar, setMobileSidebar] = useState(false);
  const showMobileSidebar = () => setMobileSidebar(!mobileSidebar);

  return (
    <>
      <header className="main-header">
        <div className="top-bar">
          <div className="container">
            <div className="top-bar__links">
              <div className="header-link">
                <NavLink to="/">Home</NavLink>
              </div>
              <div className="header-link">
                <NavLink to="/about-us">About</NavLink>
              </div>
              <div className="header-link">
                <NavLink to="/contact-us">Contact</NavLink>
              </div>
            </div>
            {/* <div className="top-bar__social-links">
              <a href="#!">
                <faIcons.FaFacebookF />
              </a>
            </div> */}
          </div>
        </div>
        <div className="container">
          <NavLink className="header-logo" to="/">
            <img src={logo} alt="logo" />
            <img src={logo1} alt="logo1" />

            {/* <h3>On Country Connections</h3> */}
          </NavLink>
          <div className="zoom-link">
            <div className="primary-btn">
              <NavLink to="/contact-us">Connect With Us</NavLink>
            </div>
          </div>
          <nav className="header-links">
            {/* <div className="header-link">
              <NavLink to="/workshops">Workshop</NavLink>
            </div> */}
            <div className="header-link">
              <NavLink to="/consultancy">Cultural Consultancy</NavLink>
            </div>
            {/* <div className="header-link has-dropdown">
              <NavLink to="#!">Training</NavLink>
              <IconContext.Provider value={{ sizw: "0.5em" }}>
                <faIcons.FaChevronDown />
              </IconContext.Provider>
              <div className="dropdown-menu">
                <NavLink to="/practitioner-training">
                  Practitioner Training
                </NavLink>
                <NavLink to="/cultural-awareness-training">
                  Cultural Awareness Training
                </NavLink>
              </div>
            </div> */}
            <div className="header-link">
              <NavLink to="/wayapa-wuurrk">wayapa wuurrk</NavLink>
            </div>
            <div className="header-link">
              <NavLink to="/we-al-li">we al-li</NavLink>
            </div>
            <div className="header-link">
              <NavLink to="/ipat">ipat</NavLink>
            </div>
            <div className="header-link">
              <NavLink to="/rebirthing-breathwork">
                rebirthing breathwork
              </NavLink>
            </div>

            {/* <div className="header-link has-dropdown">
              <NavLink to="#!">upcoming Events and training</NavLink>
              <IconContext.Provider value={{ sizw: "0.5em" }}>
                <faIcons.FaChevronDown />
              </IconContext.Provider>
              <div className="dropdown-menu">
                <NavLink to="/corporate-events">Corporate</NavLink>
                <NavLink to="/working-with-children">
                  Working With Children
                </NavLink>
                <NavLink to="/public-events">Public Events</NavLink>
              </div>
            </div> */}
            <div className="header-link">
              <a href="https://linktr.ee/JemStone">
                upcoming events and training
              </a>
            </div>
          </nav>
        </div>
      </header>

      <header className="mobile-header">
        <div className="container">
          <div className="menu-bars" onClick={showMobileSidebar}>
            <IconContext.Provider value={{ size: "1.5em", color: "#893935" }}>
              <faIcons.FaBars />
            </IconContext.Provider>
          </div>

          <Link to="/" className="header-logo">
            <img src={logo} alt="logo" />
          </Link>
        </div>
      </header>

      {mobileSidebar && (
        <div className="cart-overlay" onClick={showMobileSidebar}></div>
      )}
      <nav
        className={mobileSidebar ? "mobile-sidebar active" : "mobile-sidebar"}
      >
        <div className="container-fluid">
          <div className="mobileSidebar--header">
            <NavLink to="/" onClick={showMobileSidebar}>
              <img src={logo} alt="" />
            </NavLink>
            <button
              onClick={showMobileSidebar}
              className="mobileSidebar--close-btn"
            >
              <IconContext.Provider value={{ size: "1.8em", color: "#893935" }}>
                <aiIcons.AiOutlineClose />
              </IconContext.Provider>
            </button>
          </div>

          {/* <div className="mobile-sidebar--search">
            <form action="">
              <input type="text" name="" id="" placeholder="Search products..." />
            </form>
          </div> */}
          <div className="mobileSidebar--top-bar">
            <NavLink to="/" onClick={showMobileSidebar}>
              Home
            </NavLink>
            <NavLink to="/about-us" onClick={showMobileSidebar}>
              About
            </NavLink>
            <NavLink to="/contact-us" onClick={showMobileSidebar}>
              Contact
            </NavLink>
          </div>
          <div className="mobileSidebar--menu_section">
            <div className="mobileSidebar--menu_items">
              <div className="mobileSidebar--menu_item">
                <NavLink to="/consultancy" onClick={showMobileSidebar}>
                  Cultural Consultancy
                </NavLink>
              </div>

              <div className="mobileSidebar--menu_item">
                <NavLink to="/wayapa-wuurrk" onClick={showMobileSidebar}>
                  wayapa-wuurrk
                </NavLink>
              </div>
              <div className="mobileSidebar--menu_item">
                <NavLink to="/we-al-li" onClick={showMobileSidebar}>
                  we al-li
                </NavLink>
              </div>
              <div className="mobileSidebar--menu_item">
                <NavLink to="/ipat" onClick={showMobileSidebar}>
                  ipat
                </NavLink>
              </div>
              <div className="mobileSidebar--menu_item">
                <NavLink
                  to="/rebirthing-breathwork"
                  onClick={showMobileSidebar}
                >
                  Rebirthing breathwork
                </NavLink>
              </div>
              <div className="mobileSidebar--menu_item">
                <a
                  href="https://linktr.ee/JemStone"
                  onClick={showMobileSidebar}
                >
                  Upcoming events and training
                </a>
              </div>
            </div>
            <div className="relative mobile-btn" style={{ marginTop: "2em" }}>
              <NavLink to="/contact-us" onClick={showMobileSidebar}>
                Connect With Us
              </NavLink>
            </div>
          </div>
        </div>
      </nav>

      <header className={headerClassName}>
        <div className="top-bar">
          <div className="container">
            <div className="top-bar__links">
              <div className="header-link">
                <NavLink to="/">Home</NavLink>
              </div>
              <div className="header-link">
                <NavLink to="/about-us">About</NavLink>
              </div>
              <div className="header-link">
                <NavLink to="/contact-us">Contact</NavLink>
              </div>
            </div>
            {/* <div className="top-bar__social-links">
              <a href="#!">
                <faIcons.FaFacebookF />
              </a>
            </div> */}
          </div>
        </div>
        <div className="container">
          <Link className="header-logo">
            <img src={logo} alt="logo" />
            <img src={logo1} alt="logo1" />
          </Link>
          <div className="zoom-link">
            <div className="primary-btn">
              <Link to="/contact-us">Connect With Us</Link>
            </div>
          </div>
          <nav className="header-links">
            <div className="header-link">
              <NavLink to="/consultancy">cultural Consultancy</NavLink>
            </div>
            {/* <div className="header-link has-dropdown">
              <NavLink to="#!">Training</NavLink>
              <IconContext.Provider value={{ sizw: "0.5em" }}>
                <faIcons.FaChevronDown />
              </IconContext.Provider>
              <div className="dropdown-menu">
                <NavLink to="/practitioner-training">
                  Practitioner Training
                </NavLink>
                <NavLink to="/cultural-awareness-training">
                  Cultural Awareness Training
                </NavLink>
              </div>
            </div> */}
            <div className="header-link">
              <NavLink to="/wayapa-wuurrk">wayapa wuurrk</NavLink>
            </div>
            <div className="header-link">
              <NavLink to="/we-al-li">we al-li</NavLink>
            </div>
            <div className="header-link">
              <NavLink to="/ipat">ipat</NavLink>
            </div>
            <div className="header-link">
              <NavLink to="/rebirthing-breathwork">
                rebirthing breathwork
              </NavLink>
            </div>

            {/* <div className="header-link has-dropdown">
              <NavLink to="#!">Events</NavLink>
              <IconContext.Provider value={{ sizw: "0.5em" }}>
                <faIcons.FaChevronDown />
              </IconContext.Provider>
              <div className="dropdown-menu">
                <NavLink to="/corporate-events">Corporate</NavLink>
                <NavLink to="/working-with-children">
                  Working With Children
                </NavLink>
                <NavLink to="/public-events">Public Events</NavLink>
              </div>
            </div> */}
            <div className="header-link">
              <a href="https://linktr.ee/JemStone">
                upcoming events and training
              </a>
            </div>
          </nav>
        </div>
      </header>
    </>
  );
};

export default Header;

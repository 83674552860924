import React from "react";
import "./IpatScreen.css";
import ipat from "../images/IPAT.jpg";
import team from "../images/team1.jpg";
import Testimonial from "../components/HomeComponents/Testimonial";
import BreadCrumb from "../components/BreadCrumb/BreadCrumb";
import TestimonialSldier from "../components/TestimonialSlider/TestimonialSldier";
import Enquireform from "../components/EnquireForm/Enquireform";

function IpatScreen() {
  return (
    <>
      <section className="ipat__section">
        <BreadCrumb title={"IPAT"} />
        <div className="container">
          <div className="ipat__row">
            <div className="ipat__row-text">
              <h2>IPAT (Indigenous Psychedelic Assisted Therapies)</h2>

              <p>
                IPAT aims to support accessibility of plant medicines and their
                therapeutic use for Indigenous peoples by creating the first
                Indigenous-led psychedelic assisted therapy model in Australia.
                IPAT ensures that original knowledge and plant kin connections
                are honoured in this model as well as regeneration, reciprocity,
                and equity in this space. IPAT’s vision is to ensure First
                Nations ways of knowing being and doing are at the forefront of
                this work, advocating for culturally safe and inclusive systems
                within Australia.
              </p>
              <p>
                Right relationship with Country, Culture and Community are
                integral in this work, with respect to the wisdom of Original
                knowledge and kinship with Mother Earth’s psychedelic medicine.
                IPAT offers teachings about how to ensure that original
                knowledge is respected by practitioners in this space.
              </p>
              <p>
                IPAT can assist in preparation and integration for therapists
                working with Aboriginal and Torres Strait Islander people,
                creating a safe space for healing from historical and
                intergenerational trauma, where an Indigenous practitioner is
                required for harm minimisation.
              </p>
              <p>
                IPAT Runs a PACFA endorsed 2 day Culturally Informed Trauma
                Integrated Healing Approach to Care and Practice- An Indigenous
                Approach to Developing Worker Skills for psychedelic assisted
                therapists. This is an essential way to bring cultural
                responsiveness to your practice and will help you meet your
                AHPRA cultural competencies. Please get in touch to organise a
                training for your team or for our upcoming training dates.
              </p>
              <p>
                Jem Stone, founder of On Country Connections is also co-founder
                of IPAT
              </p>
              <p>
                Contact us below for consultancy and teaching in the psychedelic
                assisted therapy space.
              </p>
            </div>

            <div className="ipat__row-image">
              <img src={ipat} alt="ipat" />
              <img src={team} alt="team" />
            </div>
          </div>
        </div>
        {/* <div className="ipat__banner">
          <div className="ipat__banner--grid">
            <div className="ipat__grid-col">
              <img src={icon1} alt="" />
              <p>Emotional</p>
            </div>
            <div className="ipat__grid-col">
              <img src={icon2} alt="" />
              <p>Inner Peace</p>
            </div>
            <div className="ipat__grid-col">
              <img src={icon3} alt="" />
              <p>Inner Self</p>
            </div>
            <div className="ipat__grid-col">
              <img src={icon4} alt="" />
              <p>Meditation</p>
            </div>
          </div>
        </div> */}
        {/* <div className="container">
          <div className="ipat__row reverse">
            <div className="ipat__row-text">
              <h2>IPAT Title</h2>

              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Iste
                nemo porro, exercitationem ab enim in commodi et, alias quaerat
                nihil reprehenderit minus maiores ipsa odio minima saepe dolores
                dignissimos deleniti, ad ipsam at praesentium illo?
              </p>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe
                cum officia rerum, obcaecati perspiciatis facere modi libero
                fugiat aliquam voluptas pariatur facilis. Eius unde animi,
                eveniet cum vel, perferendis beatae molestias impedit quae
                dolore neque vitae? Excepturi ut iste vitae quibusdam hic
                aperiam, saepe laboriosam enim, nostrum dolores velit.
                Voluptatem.
              </p>
              <p>
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ea
                sunt voluptatem magnam voluptates culpa, modi inventore veniam
                cupiditate officia sint quia, nobis tempora, laboriosam
                temporibus quasi porro iste assumenda dolore.
              </p>
            </div>

            <div className="ipat__row-image">
              <img src={ipat} alt="ipat" />
            </div>
          </div>
        </div> */}
      </section>
      <Enquireform />
      <Testimonial />
    </>
  );
}

export default IpatScreen;

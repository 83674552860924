import React from "react";
import "./BreadCrumb.css";
import workshop from "../../images/cultural consultancy.jpg";
import { Link } from "react-router-dom";

function BreadCrumb({ title}) {
  return (
    <div className="breadcrumb">
      <div className="breadcrumb-bg">
        <img src={workshop} alt="" />
      </div>
      <div className="container">
        <div className="title__section">
          <div className="breadcrumb__links">
            <h2>{title}</h2>
            <span>
              <Link to="/">Home</Link> <p>/</p> <p>{title}</p>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BreadCrumb;

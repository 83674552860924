import React from "react";
import LandingSection from "../components/HomeComponents/LandingSection";
import TextContent from "../components/HomeComponents/TextContent";
import NewsLetter from "../components/Newsletter/NewsLetter";
import Testimonial from "../components/HomeComponents/Testimonial";
import UpcomingEvents from "../components/HomeComponents/UpcomingEvents";
import Videos from "../components/HomeComponents/Videos";

const HomeScreen = () => {
  return (
    <div className="Homescreen" style={{ width: "100%" }}>
      <LandingSection />
      <TextContent />
      <NewsLetter />
      <Videos />
      <Testimonial />
    </div>
  );
};

export default HomeScreen;

import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import "./NewsLetter.css";

const NewsLetter = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false); // Loader state
  const [success, setSuccess] = useState(false); // Success state
  const [error, setError] = useState(false); // Error state

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true); // Show loader when sending starts

    // Replace with your EmailJS service ID, template ID, and user ID
    emailjs
      .send(
        "service_ipxbjba",
        "template_b52w60e",
        { email },
        "m6WiG50t9Q4tgBgY_"
      )
      .then(
        (result) => {
          setSuccess(true);
          setEmail(""); // Clear the input after successful submission
          setLoading(false); // Hide loader after success
        },
        (error) => {
          setError(true);
          setLoading(false); // Hide loader after error
        }
      );
  };

  return (
    <div className="newsletter">
      <div className="form-container">
        <h3>Connect with Us</h3>
        <p>For updates about retreat dates, workshops, training, and more</p>
        <form onSubmit={handleSubmit} className="news-letter__form">
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <input type="submit" value="Subscribe" />
        </form>
        {loading && <p>Processing your request...</p>}
        {success && <p>Your subscription was successful!</p>}
        {error && <p>Something went wrong. Please try again later.</p>}
      </div>
    </div>
  );
};

export default NewsLetter;

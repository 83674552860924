import React from "react";
import "./Footer.css";
import footerBg from "../../images/footer-bg.jpg";
import logo1 from "../../images/footerlogo.png";
import { Link } from "react-router-dom";
import * as SiIcons from "react-icons/si";
import { IconContext } from "react-icons";

const Footer = () => {
  const mobileMenuItems = [
    { to: "/consultancy", name: "Cultural Consultancy" },
    { to: "/wayapa-wuurrk", name: "Wayapa Wuurrk" },
    { to: "/we-al-li", name: "we al-li" },
    { to: "/ipat", name: "IPAT" },
    { to: "/rebirthing-breathwork", name: "rebirthing breathwork" },
    { to: "https://linktr.ee/JemStone", name: "Upcoming events and training" },
  ];
  const currentYear = new Date().getFullYear();
  return (
    <footer>
      <div className="container">
        <Link className="footer-logo" to="/">
          <img src={logo1} alt="oncountry logo" />
        </Link>
        <div className="tagline">
          <p>Reconnecting the disconnect</p>
        </div>
        <div className="footer-buttons">
          <div className="primary-btn">
            <Link to="/contact-us">Connect With Us</Link>
          </div>
        </div>
        <div className="footer-menu">
          <>
            <div className="footer-links">
              {mobileMenuItems.map((item) => (
                <div className="footer-link" key={item.name}>
                  <Link to={item.to}>{item.name}</Link>
                </div>
              ))}
            </div>

            <div className="footer-icons">
              <IconContext.Provider value={{ size: "1.5em" }}>
                <a
                  className="icon"
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.facebook.com/onelittlewarriorwoman"
                >
                  <SiIcons.SiFacebook />
                </a>
                <a
                  className="icon"
                  href="https://www.instagram.com/jem_stone1/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <SiIcons.SiInstagram />
                </a>
                <a
                  className="icon"
                  href="https://au.linkedin.com/in/jem-stone-4aa311273"
                  target="_blank"
                  rel="noreferrer"
                >
                  <SiIcons.SiLinkedin />
                </a>
                <a
                  className="icon"
                  href="https://linktr.ee/JemStone"
                  target="_blank"
                  rel="noreferrer"
                >
                  <SiIcons.SiLinktree />
                </a>
              </IconContext.Provider>
            </div>
          </>
        </div>
      </div>
      <div className="bottom-footer">
        <div className="footer-widget">
          <Link>Terms & Conditions</Link>
          <Link>Privacy Policy</Link>
          <Link>Cookies</Link>
        </div>
        <div className="copyright">&copy; {currentYear} OnCountry</div>
      </div>
    </footer>
  );
};

export default Footer;

import React, { useEffect, useState } from "react";
import axios from "axios";
import "./TextContent.css";
import watermark from "../../images/watermark1.png";

const TextContent = () => {
  const [imageURL, setImageURL] = useState("");

  useEffect(() => {
    fetchImage();
  }, []);

  const fetchImage = async () => {
    try {
      const response = await axios.get(
        "https://admin.oncountryconnections.com.au/api/image"
      );
      // Assuming your backend serves the images from 'https://admin.oncountryconnections.com.au'
      const imageUrl = `https://admin.oncountryconnections.com.au${response.data.url}`;
      setImageURL(imageUrl);
    } catch (error) {
      console.error("There was an error fetching the image!", error);
    }
  };
  return (
    <div className="consultancy__screen home-content">
      <div className="container">
        <div className="consultancy-row">
          <div className="consultancy--text">
            <h2>Acknowledgment of Country</h2>
            <p>
              Living in Earth Connection and walking gently in stewardship, On
              Country Connections acknowledges all of the Ancestors and Elders
              past and present of the unceded lands now known as Australia in
              which we operate.
            </p>
            <p>
              We also acknowledge the young ones growing up on these lands and
              with purpose acknowledge our children are our promise of tomorrow,
              recognising that our impact affects at least the next seven
              generations.
            </p>
            <p>
              We acknowledge the collective Ancestry, yours and mine, as the
              threads weaving our collective future and remembrance as Earth
              people.
            </p>
            <p>May we all walk gently and live in reverence.</p>
          </div>
          <div className="consultancy--image">
            {imageURL && <img src={imageURL} alt="Consultancy" />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TextContent;

import React, { useEffect, useState } from "react";
import axios from "axios";
import "./About.css";
import BreadCrumb from "../components/BreadCrumb/BreadCrumb";
import jem from "../images/jemimage1.jpg";

function About() {
  const [imageURLs, setImageURLs] = useState([]);

  useEffect(() => {
    fetchImages();
  }, []);

  const fetchImages = async () => {
    try {
      const response = await axios.get(
        "https://admin.oncountryconnections.com.au/api/aboutimage"
      );
      console.log("API response:", response.data); // Log the API response
      if (Array.isArray(response.data.urls)) {
        setImageURLs(response.data.urls);
      } else {
        console.error(
          "Image URLs are not in the expected format:",
          response.data.urls
        );
      }
    } catch (error) {
      console.error("Error fetching images:", error);
    }
  };

  return (
    <>
      <BreadCrumb title={"Jem Stone"} />
      <section className="about__section">
        <div className="container">
          <div className="about__row">
            <div className="about__image">
              <img src={jem} alt="" />
            </div>
            <div className="about__text">
              <h2>Purpose</h2>
              <p>
                Creating safe, inclusive spaces for healing through
                reconnection. Moving through personal, ancestral, collective
                trauma.
              </p>
              <p>
                Grounding and Connecting, remembering our purpose as earth
                people
                <li>Earth</li>
              </p>
              <p>
                Breathing, connecting with the life force that runs through us
                <li>Self</li>
              </p>
              <p>
                Celebrating cultural diversity and recreating the story in
                unified safety
                <li>Each other</li>
              </p>
              <p>
                Jem Stone the founder of On Country Connections. Jem is a First
                Nations Woman with mixed heritage, Cultural Educator, Wellness
                Practitioner and Trainer who is passionately integrating
                original knowledge systems into corporate, education and
                wellness spaces through reconnection and decolonised learning
                methods.
              </p>
              <p>
                Working and training in the wellness industry for over twenty
                years, Jem Stone dedicates much of her time learning from Elders
                and other cultural teachers and considers herself a contemporary
                Earth custodian. Jem offers Cultural consultancy, embodied
                wellness practices and culturally informed trauma integrated
                healing approach to care and practice. Jem is trained in
                psychedelic assisted therapy.
              </p>
            </div>
          </div>
          {/* <div className="about__grid">
            {imageURLs.map((url, index) => (
              <div className="about__image" key={index}>
                <img src={url} alt={`About ${index + 1}`} />
              </div>
            ))}
          </div> */}
        </div>
      </section>
    </>
  );
}

export default About;

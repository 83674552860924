import React from "react";
import BreadCrumb from "../components/BreadCrumb/BreadCrumb";
import Workshop from "../components/Workshops/Workshop";
import TestimonialSldier from "../components/TestimonialSlider/TestimonialSldier";
import Testimonial from "../components/HomeComponents/Testimonial";

const WorkShopScreen = () => {
  return (
    <div className="workshopscreen" style={{ width: "100%" }}>
      <BreadCrumb title={"Workshops"} />
      <Workshop />
      <Testimonial />
    </div>
  );
};

export default WorkShopScreen;

import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper/modules";
import "./Testimonial.css";
import testimonials from "../../Testimonialdata";
import * as FaIcons from "react-icons/fa";
import { IconContext } from "react-icons";

const Testimonial = () => {
  return (
    <section className="testimonial-slide__section">
      <h2>Testimonials</h2>
      <div className="container">
        <Swiper
          slidesPerView={1}
          spaceBetween={30}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          loop={true}
          navigation={true}
          modules={[Pagination, Navigation]}
          className="mySwiper"
        >
          {testimonials.map((testimonial) => (
            <SwiperSlide
              className="testimonial-slide__single"
              key={testimonial.id}
            >
              <div className="slide-inner">
                <div className="quote q1">
                  <p>
                    <FaIcons.FaQuoteLeft />
                  </p>
                </div>
                <div className="quote q2">
                  <p>
                    <FaIcons.FaQuoteRight />
                  </p>
                </div>
                <div className="rating">
                  <IconContext.Provider
                    value={{ size: "1.2em", color: "orange" }}
                  >
                    <FaIcons.FaStar />
                  </IconContext.Provider>
                  <IconContext.Provider
                    value={{ size: "1.2em", color: "orange" }}
                  >
                    <FaIcons.FaStar />
                  </IconContext.Provider>
                  <IconContext.Provider
                    value={{ size: "1.2em", color: "orange" }}
                  >
                    <FaIcons.FaStar />
                  </IconContext.Provider>
                  <IconContext.Provider
                    value={{ size: "1.2em", color: "orange" }}
                  >
                    <FaIcons.FaStar />
                  </IconContext.Provider>
                  <IconContext.Provider
                    value={{ size: "1.2em", color: "orange" }}
                  >
                    <FaIcons.FaStar />
                  </IconContext.Provider>
                </div>
                <h3 id="author__name">{testimonial.name}</h3>
                <p id="author__message">{testimonial.message}</p>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default Testimonial;

import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";

import "./TestimonialSlider.css";
import { Pagination, Autoplay } from "swiper/modules";

import * as FaIcons from "react-icons/fa";
import { IconContext } from "react-icons";
import testimonials from "../../Testimonialdata";

function TestimonialSldier() {
  return (
    <>
      <Swiper
        slidesPerView={3}
        breakpoints={{
          350: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 50,
          },
        }}
        spaceBetween={30}
        loop={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[Pagination, Autoplay]}
        className="mySwiper"
      >
        {testimonials.map((testimonial) => (
          <SwiperSlide
            className="testimonial__swiperslide"
            key={testimonial.id}
          >
            <div className="left-quote">
              <FaIcons.FaQuoteLeft />
            </div>
            <IconContext.Provider value={{ size: "1.2em", color: "orange" }}>
              <div className="review__rating">
                <FaIcons.FaStar />
                <FaIcons.FaStar />
                <FaIcons.FaStar />
                <FaIcons.FaStar />
                <FaIcons.FaStar />
              </div>
            </IconContext.Provider>
            <p>{testimonial.message}</p>
            <h3>{testimonial.name}</h3>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}

export default TestimonialSldier;
